import React, {ChangeEvent, FC, useState} from "react";
import '../../assets/styles/area-params.css';
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
    setSearchActiveObjects,
    filterSearchYear,
    filterSearchArea,
    setSearch,
    filterSearchCity,
    filterSearchPrice,
    filterSearchCurrency,
    filterSearchReset,
    filterSearchTags,
    filterSearchBathRoom,
    filterSearchRoom,
    filterSearchPropertyType, filterSearchCategory, filterSearchCitizenship, filterSearchFurniture, filterSearchIkamet
} from "../../app/ObjectSlice";


interface InputPriceRangeProps {
    min: number;
    max: number;
    onChange: Function;
}

const AreaParams: FC<InputPriceRangeProps> = () => {
    const [checkedYear, setCheckedYear]: any = useState([]);
    const obj = useAppSelector(state => state.objects.objects);
    const searchActiveObjects = useAppSelector(state => state.objects.searchActiveObjects);
    const areaValue = useAppSelector(state => state.objects.search.area);
    const year = obj
        .map(item => item.values[55].value[0].recordValues[18] && new Date(item.values[55].value[0].recordValues[18]).getFullYear())
        .filter(item => item !== null);

    const uniqueYear = [...Array.from(new Set(year))].sort();
    const dispatch = useAppDispatch();


    const checkMinArea = (minArea: any) => {
        if (Number(minArea) > areaValue.max) {
            return {
                min: areaValue.min,
                max: areaValue.max
            }
        }

        return Number(minArea) < 0 ? {min: 0, max: areaValue.max} : {min: Number(minArea), max: areaValue.max}
    }

    const checkMaxArea = (maxArea: any) => {
        if (Number(maxArea) < areaValue.min) {
            return {
                min: areaValue.min,
                max: areaValue.max
            }
        }

        return Number(maxArea) > 500 ? {max: 500, min: areaValue.min} : {max: Number(maxArea), min: areaValue.min}
    }

    const handleCheckYear = (event: any) => {
        let updatedList = [...checkedYear];
        if (event.target.checked) {
            updatedList = [...checkedYear, event.target.value];
            dispatch(setSearchActiveObjects(searchActiveObjects + 1))
        } else {
            updatedList.splice(checkedYear.indexOf(event.target.value), 1);
            dispatch(setSearchActiveObjects(searchActiveObjects - 1))

        }
        setCheckedYear(updatedList);
        dispatch(setSearch({key: 'year', value: updatedList}));

        dispatch(filterSearchCurrency());
        dispatch(filterSearchPrice());
        dispatch(filterSearchCity());
        dispatch(filterSearchYear());
        dispatch(filterSearchArea());
        dispatch(filterSearchTags());
        dispatch(filterSearchBathRoom());
        dispatch(filterSearchRoom());
        dispatch(filterSearchPropertyType());
        dispatch(filterSearchCategory());
        dispatch(filterSearchCitizenship());
        dispatch(filterSearchIkamet());

        dispatch(filterSearchReset());
    };

    return (
        <div className="area-params">
            <div className="room-params-block">
                <div className="room-params-title margin-class">Общая площадь:</div>
                <div className="area-inputs">
                    <input className="filter-input left-input"
                           value={areaValue.min.toLocaleString()}
                           placeholder="От"
                           onChange={(event: ChangeEvent<HTMLInputElement>) => {
                               const minArea = event.target.value.replace(/\s/g, '');

                               dispatch(setSearch({key: 'area', value: checkMinArea(minArea)}));

                               dispatch(filterSearchCurrency());
                               dispatch(filterSearchPrice());
                               dispatch(filterSearchCity());
                               dispatch(filterSearchYear());
                               dispatch(filterSearchArea());
                               dispatch(filterSearchTags());
                               dispatch(filterSearchBathRoom());
                               dispatch(filterSearchRoom());
                               dispatch(filterSearchPropertyType());
                               dispatch(filterSearchCategory());
                               dispatch(filterSearchCitizenship());
                               dispatch(filterSearchFurniture());
                               dispatch(filterSearchIkamet());

                               dispatch(filterSearchReset());

                           }}
                    />
                    <input className="filter-input right-input"
                           value={areaValue.max.toLocaleString()}
                           placeholder="До"
                           onChange={(event: ChangeEvent<HTMLInputElement>) => {
                               const maxArea = event.target.value.replace(/\s/g, '');

                               dispatch(setSearch({key: 'area', value: checkMaxArea(maxArea)}));

                               dispatch(filterSearchCurrency());
                               dispatch(filterSearchPrice());
                               dispatch(filterSearchCity());
                               dispatch(filterSearchYear());
                               dispatch(filterSearchArea());
                               dispatch(filterSearchTags());
                               dispatch(filterSearchBathRoom());
                               dispatch(filterSearchRoom());
                               dispatch(filterSearchPropertyType());
                               dispatch(filterSearchCategory());
                               dispatch(filterSearchCitizenship());
                               dispatch(filterSearchFurniture());
                               dispatch(filterSearchIkamet());

                               dispatch(filterSearchReset());
                           }}
                    />
                </div>

            </div>
            <div className="area-params-block">
                <div className="room-params-title margin-class">Выберите год сдачи:</div>
                <ul className="filter-tabs-list">
                    {uniqueYear.map((item: any, index: number) => (
                        <li key={index}>
                            <input id={item} value={item} type="checkbox" onChange={handleCheckYear} />
                            <label htmlFor={item}>
                                <span className="filter-label-circle"></span>
                                <span className="filter-label-title" data-search-button-name={item}>{item}</span>
                            </label>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default AreaParams;